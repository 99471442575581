<template>
  <div class='authorization'>
    <!-- 头部 -->
    <!-- <div class='header'>
      <div class='flex align-center hp100 header_box bbox'>
        <img src='../assets/logo.png' alt='' width='240' height='20' />
      </div>
    </div> -->
    <!-- 主体 -->
    <div class='authorization_box main_box'>
      <div class='textc tit f20'>正在跳转...</div>
      <div class='flex align-center justify-center loading'>
        <img
          src='../assets/autho_logo.png'
          alt=''
          width='322'
          height='26'
          style='margin-left: -116px'
        />
        <div class='border'></div>
        <img src='../assets/autho_loading.gif' alt='' height='26' />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      redirect_url: '',
      auth_url: '',
      ticket: '',
      loginName: 'admin'
    }
  },
  methods: {
    getQueryVariable (variable) {
        // 获得了当前链接的中?号后的参数,location.search的值就是 '?xxx=xxx ' ,substr(1)就是从索引1开始截取 
      var query = window.location.search.substring(1) 
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] == variable) {
          return pair[1]
        }
      }
      return ''
    },
    getLocalStorage (key) {
      // 取出对象
      let item = localStorage.getItem(key)
      // 先将拿到的试着进行json转为对象的形式
      try {
        item = JSON.parse(item)
      } catch (error) {
        // eslint-disable-next-line no-self-assign
        item = item
      }
      // 如果有startTime的值，说明设置了失效时间
      if (item && item.startTime) {
        let date = new Date().getTime()
        // 如果大于就是过期了，如果小于或等于就还没过期
        if (date - item.startTime > item.expires) {
          localStorage.removeItem(name)
          return ''
        } else {
          return item.value
        }
      } else {
        return ''
      }
    },
    genTikect () {
      var that = this
      this.$http
        .post('/auth/genTicket', {
          loginName: this.loginName
        })
        .then(function (response) {
          console.log(response)
          that.ticket = response.data.data
          var url = decodeURIComponent(that.auth_url)
          window.location =
            url +
            '?redirect_url=' +
            that.redirect_url +
            '&ticket=' +
            that.ticket
        })
    }
  },
  created () {
    this.redirect_url = this.getQueryVariable('redirect_url')
    this.auth_url = this.getQueryVariable('auth_url')
    var token = this.getLocalStorage('token')
    // 判断用户登录状态
    //  alert(token);

    if (token != null && token != '') {
      // 已登录
      if (this.redirect_url != null && this.redirect_url != '') {
        // 子系统链接不为空跳转到系统
        this.genTikect()
      } else {
        // 进入sso首页
        this.$router.push({ path: '/index' })
      }
    } else {
      // 未登录
      this.$router.push({
        path: '/login',
        query: { redirect_url: this.redirect_url, auth_url: this.auth_url }
      })
    }
  }
}
</script>

<style  scoped>
.authorization_box {
  background-color: #f8f8f8;
  height: 100vh;
}
.authorization_box .tit {
  padding-top: 12%;
}
.authorization_box .loading {
  margin-top: 8%;
}
.authorization_box .border {
  width: 1px;
  height: 30px;
  background: #767676;
  margin: 0 30px;
}
</style>
